import { Link } from "@material-ui/core";
import React from "react";

import styles from "./Footer.module.scss";

export const Footer = () => {
    const gotoPrivacyPolicy = () => {
        // navigateTo(selectPrivacyPolicyRoute());
    };

    return (
        <div className={styles.bottom}>
            <span className={styles.text}>CxpStudio В© 2001-{new Date().getFullYear()}</span>
            <span className={styles.delimiter}>|</span>
            <span className={styles.text}>
                <Link className={styles.linkPrivacyPolicy} onClick={gotoPrivacyPolicy}>
                    Privacy Policy Terms of Use
                </Link>
            </span>
        </div>
    );
};
