import React from "react";

import { MainTemplate } from "../../components/MainTemplate";
import { Page } from "../../store/state";

import { DesignLayout } from "./DesignLayout";
import { ProjectLayout } from "./ProjectLayout";

export interface DesignPageProps {
    match: {
        params: {
            id?: string;
        };
    };
}

export const DesignPage = (props: DesignPageProps) => {
    const { id } = props.match.params;

    if (id) {
        return (
            <MainTemplate page={id === "other" ? Page.OtherProjects : Page.DesignProject}>
                <ProjectLayout id={id} />
            </MainTemplate>
        );
    }

    return (
        <MainTemplate page={Page.Design}>
            <DesignLayout />
        </MainTemplate>
    );
};
