import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { DesignPage } from "../pages/design/DesignPage";
import { PrivacyPolicyPage } from "../pages/privacy-policy/PrivacyPolicyPage";

import { selectDefaultRoute, selectDesignRoute, selectPrivacyPolicyRoute } from "./router.selectors";

export const getRoutes = (): React.ReactFragment => (
    <Switch>
        <Route exact={true} path={`${selectDesignRoute()}/:id`} component={DesignPage} />
        <Route path={selectDesignRoute()} component={DesignPage} />
        <Route path={selectPrivacyPolicyRoute()} component={PrivacyPolicyPage} />
        <Route>
            <Redirect to={selectDefaultRoute()} />
        </Route>
    </Switch>
);
