import produce, { Draft } from "immer";

import { AppState, Page } from "../state";

import { AppActions, SET_PAGE } from "./app.types";

/**
 *
 * Initial state
 *
 */
export const initState: AppState = {
    page: Page.Design,
};

/**
 *
 * Reducer
 *
 */
export const appReducer = produce((draft: Draft<AppState>, action: AppActions) => {
    switch (action.type) {
        case SET_PAGE:
            draft.page = action.payload;
            return;
    }
}, initState);
