import React from "react";

import { MainTemplate } from "../../components/MainTemplate";
import { Page } from "../../store/state";

import styles from "./PrivacyPolicyPage.module.scss";

export const PrivacyPolicyLayout = () => {
    return <div className={styles.container}>PRIVACY_POLICY_LAYOUT</div>;
};

export const PrivacyPolicyPage = () => (
    <MainTemplate page={Page.PrivacyPolicy}>
        <PrivacyPolicyLayout />
    </MainTemplate>
);
