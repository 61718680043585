// +++++++++++++++
// State
// +++++++++++++++
export interface State {
    app: AppState;
}

// +++++++++++++++
// AppState
// +++++++++++++++
export interface AppState {
    page: Page;
}

export enum Page {
    Undefined = "",
    Design = "design",
    DesignProject = "design_project",
    Modeling = "modeling",
    OtherProjects = "other_projects",
    PrivacyPolicy = "privacy_policy",
}
