import { Button } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import styles from "./ProjectPreview.module.scss";

export interface ProjectPreviewProps {
    back: string;
    index: number;
    label: string;
    preview: string;
    isReverse?: boolean;
    onSelect: () => void;
}

export const ProjectPreview = (props: ProjectPreviewProps) => {
    const { back, index, label, preview, onSelect } = props;
    const isReverse = !(index % 2);
    const imageComponent = <img src={preview} className={styles.image} alt="project" onClick={onSelect} />;

    const textComponent = (
        <span className={styles.info}>
            <span className={styles.content}>
                <span className={styles.back}>
                    <img src={back} className={styles.backImage} alt="" />
                </span>
                <span className={styles.label}>{label}</span>
                <Button className={styles.buttonSeeProject} variant="outlined" onClick={onSelect}>
                    See project
                </Button>
            </span>
        </span>
    );

    return (
        <div className={styles.container}>
            <div className={clsx(styles.item, isReverse && styles.reverse)}>
                {imageComponent}
                {textComponent}
            </div>
        </div>
    );
};
