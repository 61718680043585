import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import styles from "./ScrollUpButton.module.scss";

export const ScrollUpButton = () => {
    const [showScroll, setShowScroll] = useState(false);
    const [isMovingUp, startMovingUp] = useState(false);

    useEffect(() => {
        const checkScrollTop = () => {
            if (!isMovingUp && !showScroll && window.pageYOffset > 400) {
                setShowScroll(true);
            } else if ((isMovingUp || showScroll) && window.pageYOffset <= 400) {
                setShowScroll(false);
                startMovingUp(false);
            }
        };

        window.addEventListener("scroll", checkScrollTop);

        return () => window.removeEventListener("scroll", checkScrollTop);
    }, [showScroll, isMovingUp]);

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setShowScroll(false);
        startMovingUp(true);
    };

    return (
        <div className={clsx(styles.container, !showScroll && styles.hidden)} onClick={scrollTop}>
            <ArrowDropDownCircleOutlinedIcon fontSize="inherit" />
        </div>
    );
};
