import { Button, Link, Toolbar, Typography } from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import TelegramIcon from "@material-ui/icons/Telegram";
import clsx from "clsx";
import React from "react";

import { navigateTo } from "../router/browserHistory";
import { selectDesignRoute, selectProjectRoute } from "../router/router.selectors";
import { Page } from "../store/state";

import styles from "./Header.module.scss";

export interface HeaderProps {
    page: Page;
}

const firstname = ".irene";
const domain = "cxp";
const mailservice = "gmail.";

export const Header = (props: HeaderProps) => {
    const { page } = props;

    const isDesign = page === Page.Design;
    const isModeling = page === Page.Modeling;

    const onClickOtherProjects = () => {
        navigateTo(selectProjectRoute("other"));
    };

    const buttonOtherProjects = (
        <span key={"project_other"} className={styles.otherProjectsBlock}>
            <Button
                className={clsx(styles.buttonSwitch, styles.design, !isDesign && styles.disabled)}
                variant="outlined" onClick={onClickOtherProjects}>
                Other projects
            </Button>
        </span>
    );

    const onClickDesign = () => {
        navigateTo(selectDesignRoute());
    };

    const buttonDesign = (
        <Button
            variant="contained"
            className={clsx(styles.buttonSwitch, styles.design, isDesign && styles.disabled)}
            onClick={onClickDesign}
        >
            Design
        </Button>
    );

    const onContacts = () => {
        const arr = ["mail", "to:", domain, firstname, "@", mailservice, "com", "?subject=from website"];
        window.location.href = arr.join("");
    };

    const pageLabel = isModeling ? "Visualisation" : "Design";

    return (
        <Toolbar className={styles.toolbar}>
            <Typography className={styles.name} variant="h4" onClick={onClickDesign}>
                <span className={styles.author}>Irina Orlova</span>
                <span className={styles.pageLabel}> &nbsp; {pageLabel}</span>
            </Typography>
            <div className={styles.bubble} />
            <div className={styles.contacts}>
                <Link href="https://www.instagram.com/nordicdesign3d/" className={styles.instagram} target="_blank">
                    <InstagramIcon color="inherit" />
                </Link>
                <Link className={styles.email} onClick={onContacts}>
                    <MailOutlineIcon color="inherit" />
                </Link>
                <Link className={styles.telegram} href="https://t.me/OrlovaCxp" target="_blank">
                    <TelegramIcon color="inherit" />
                </Link>
            </div>
            {/*{buttonModeling}*/}
            {/*{buttonDesign}*/}
            {isDesign ? buttonOtherProjects : buttonDesign}
        </Toolbar>
    );
};
