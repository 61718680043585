import { applyMiddleware, combineReducers, compose, createStore, Middleware, Store } from "redux";
import thunk from "redux-thunk";

import { appReducer } from "./app/app.reducer";
import { State } from "./state";

export const createRootReducer = () =>
    combineReducers({
        app: appReducer,
    });

export function configureStore(): Store<State> {
    const middlewareList: Middleware[] = [thunk];

    const composeEnhancers =
        (process.env.NODE_ENV !== "production" &&
            typeof window === "object" &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose;

    const store = createStore(createRootReducer(), undefined, composeEnhancers(applyMiddleware(...middlewareList)));

    if (process.env.NODE_ENV === "development" && (module as any).hot) {
        (module as any).hot.accept(createRootReducer(), () => {
            store.replaceReducer(createRootReducer());
        });
    }

    return store as any;
}
