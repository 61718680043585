import { Button } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { ScrollUpButton } from "../../components/ScrollUpButton";
import { getBrowserHistory } from "../../router/browserHistory";

import styles from "./ProjectLayout.module.scss";
import { projectsData } from "./projectsData";

export interface ProjectLayoutProps {
    id: string;
}

export const ProjectLayout = (props: ProjectLayoutProps) => {
    const { id } = props;

    const [loadedCount, setLoadedCount] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    const onButtonBack = () => {
        getBrowserHistory().goBack();
    };

    const project = projectsData.find((project) => project.id === id);

    if (!project) {
        return null;
    }

    let gallery = [];

    if (id === "other") {
        const otherProjectsData = [...project.images];

        gallery = otherProjectsData.map((img, index) => {
            return <div key={img} className={styles.item} />;
        });

        const loadedItems = otherProjectsData.slice(0, loadedCount + 1);

        loadedItems.forEach((img, index) => {
            const fullImage = `./assets/design/${img}`;

            const onLoad = () => {
                if (loadedCount <= otherProjectsData.length) {
                    setLoadedCount(loadedCount + 1);
                }
            };

            gallery[index] = (
                <div key={fullImage} className={styles.placeholder}>
                    <div className={clsx(styles.loader, loadedCount > index && styles.loaderHidden)} />
                    <div className={clsx(styles.imageBox)}>
                        <div className={clsx(styles.magnifier)}>
                            <img alt="" className={clsx(styles.image)} src={fullImage} onLoad={onLoad} />
                            {/*<img alt="" className={styles.watermark} src={watermark} />*/}
                        </div>
                    </div>
                </div>
            );
        });
    } else {
        const images = project.images.map((image) => {
            return (
                <div key={image} className={styles.placeholder}>
                    {/*<div className={clsx(styles.loader, isFullImageLoaded && styles.loaderHidden)} />*/}
                    <div className={styles.imageBox}>
                        <img alt="" className={styles.image} src={image} />
                        {/*<img alt="" className={styles.watermark} src={watermark} />*/}
                    </div>
                </div>
            );
        });

        const videos =
            project.videos?.map((video, index) => {
                return (
                    <div key={"video_" + index} className={styles.placeholder}>
                        <div className={styles.imageBox}>
                            <video width={video.width} height={video.height} controls={true}>
                                <source src={video.link} type={video.type} />
                                The video tag is not supported by your browser.
                                <a href={video.link}>Download the video</a>.
                            </video>
                        </div>
                    </div>
                );
            }) || [];

        const pano2vr =
            project.pano2vr?.map((item, index) => {
                const onClick = () => {
                    window.open(item.link, "Panorama 360");
                };
                return (
                    <div key={"pano2vr_" + index} className={styles.panoLink}>
                        <div className={styles.panoLink} onClick={onClick}>
                            <span>{item.title}</span>
                        </div>
                    </div>
                );
            }) || [];

        gallery = [...pano2vr, ...images, ...videos];
    }

    return (
        <div className={styles.container}>
            {gallery}
            <Button className={styles.buttonSeeProject} variant="outlined" onClick={onButtonBack}>
                Back
            </Button>
            <ScrollUpButton />
        </div>
    );
};
