import { createHashHistory } from "history";

// const browserHistory = createMemoryHistory({});
const browserHistory = createHashHistory({});
// const browserHistory = createBrowserHistory({});

export const getBrowserHistory = () => browserHistory;

export const navigateTo = (url: string) => {
    browserHistory.push(url);
};

export const redirectTo = (url: string) => {
    browserHistory.replace(url);
};
