import { Button } from "@material-ui/core";
import React from "react";

import { navigateTo } from "../../router/browserHistory";
import { selectProjectRoute } from "../../router/router.selectors";

import styles from "./DesignLayout.module.scss";
import { ProjectPreview } from "./ProjectPreview";
import { projectsData } from "./projectsData";

export const DesignLayout = () => {
    const onSelectProject = (id: string) => () => {
        navigateTo(selectProjectRoute(id));
    };

    const projects = projectsData.map((project, index) => {
        const { backInfo, id, label, preview } = project;

        if (id === "other") {
            return (
                <span key={"project_" + index} className={styles.otherProjectsBlock}>
                    <Button className={styles.otherProjectsButton} variant="outlined" onClick={onSelectProject(id)}>
                        Other projects
                    </Button>
                </span>
            );
        }

        return (
            <ProjectPreview
                key={"project_" + index}
                back={backInfo}
                index={index}
                label={label}
                preview={preview}
                onSelect={onSelectProject(id)}
            />
        );
    });

    return <div className={styles.container}>{projects}</div>;
};
